

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$emr_frontend-primary: mat.define-palette(mat.$indigo-palette);
$emr_frontend-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$emr_frontend-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$emr_frontend-theme: mat.define-light-theme((
  color: (
    primary: $emr_frontend-primary,
    accent: $emr_frontend-accent,
    warn: $emr_frontend-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($emr_frontend-theme);

:root {
  --bg: #0d4259;
  /* --orange: #F0B00C; */
  --orange: #f0950c;
  --line: #d9d9d9;
  --grey: #f3f3f3;
  --white: #ffffff;
  --black: #000000;
}

html, body { height: 100%; }
body { margin: 0; font-family: 'Inter', sans-serif!important; }



/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
@import 'ngx-toastr/toastr';
.mat-drawer-side
{
  border: none;
}

mat-error
{
  font-size: 12px
}
.mat-icon
{
  color: #000000;
}
.cdk-header-cell {
  font-size: 15px;
  font-weight: 500;
}
.mat-mdc-table .mdc-data-table__row:hover{
  background-color: #e5f7ffbf !important;
  
  }
.mdc-data-table__cell
{
  color:var(--bg);
  font-weight: 500;
  font-size: 14px;
}
  .mat-mdc-header-cell
  {
    padding:0px;
  }
  .mat-row:hover
 {
  background-image:linear-gradient(to left, #e5f7ffbf 99.5%,#f0950c 1%)!important;
 }

 .mat-mdc-header-cell {
  color: rgba(0,0,0,.54);
  font-size: 14px;
  font-weight: 700;

}
fieldset.scheduler-border {
  border: 1px groove gray !important;
  padding: 0 1.4em 1.4em 1.4em !important;
  margin: 0 0 1.5em 0 !important;
  -webkit-box-shadow: 0px 0px 0px 0px #000;
  box-shadow: 0px 0px 0px 0px #000;
  margin-top: 10px !important;
}

legend.scheduler-border {
  font-weight: 550 !important;
  text-align: left !important;
  width: auto;
  padding: 0 10px;
  border-bottom: none;
  margin-top: -15px;
  background-color: #0d4259;
  font-size: 15px;
  color: #fff;
    border: 1px solid #ddd;
}

label
{
    margin-bottom: 10px;
    color: #0d4259;
    font-weight: 550 !important;
}
.errors
{
  color: red;
  font-size: 12px;
}
.btn-close
{
  background-color: #f9dcdd!important;
  outline: none!important;
  border: none;
}
.btn-cancel
{
  background-color: #f9dcdd!important;
  text-align: center;
  text-transform: capitalize;
  color: #625858;
  font-weight: 600;
  font-size: 12px;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 0.1rem 0.2rem #828282;
}
.btn-save
{
  background-color:var(--bg)!important;;
  text-align: center;
  text-transform: capitalize;
  color: #fff!important;;
  cursor: pointer;
  font-weight: 600;
  font-size: 12px;
  border-radius: 5px;
  box-shadow: 0 0.1rem 0.2rem #828282;
}
.modal-content {
  background: #f0f0f0!important;
}
.modal-header {
  background: #fff;
  height: 44px;
}
.modal-header > h3
{
  text-align: center;
  width: 100%;
  font-size: 20px;
}
.action_menu
{
  button {
    padding: 8px;
    margin-left: 10px;
    min-height: 0px!important;
    background: #fff;
  }
}
